let dropLifeCycle = [
    {
        name: 'On',
        value : 1
    },
    {
        name: 'Off',
        value : 0
    },
]
  export default dropLifeCycle;
  