let infoGrafik = [
  {
    id: 1,
    name: "Jam",
    value: "Day",
  },
  {
    id: 2,
    name: "Minggu",
    value: "Week",
  },
  {
    id: 3,
    name: "Bulan",
    value: "Month",
  },
  {
    id: 4,
    name: "Tahun",
    value: "Year",
  },
];
export default infoGrafik;
