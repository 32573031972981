let kondisiAutomatis = [
  {
    name: "> (lebih dari)",
    value: ">",
  },
  {
    name: "< (kurang dari)",
    value: "<",
  },
];
export default kondisiAutomatis;
