let dashboardControlMenu = [
  {
    id: 1,
    name: "Automation",
  },
  {
    id: 2,
    name: "History",
  },
];
export default dashboardControlMenu;
