let dashboardMenu = [
  {
    id: 1,
    name: 'Sensor',
  },
  {
    id: 2,
    name: 'Aktuator',
  },
]
export default dashboardMenu;
