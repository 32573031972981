let automationMenu = [
  {
    id: 1,
    name: "By Sensor",
  },
  {
    id: 2,
    name: "Penjadwalan",
  },
];
export default automationMenu;
